import { Button } from "@nextui-org/button";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/modal";
import React from "react";
import SearchForm from "../SearchBox/SearchForm";

export default function MobileSearchForm({isOpen,onOpenChange,categories}) {
    return (
        <Modal 
            backdrop="blur"
            isOpen={isOpen} 
            placement="top"
            onOpenChange={onOpenChange} 
        >
            <ModalContent>
                {(onClose) => (
                <>
                    <ModalHeader className="flex flex-col gap-1">Tìm Phim</ModalHeader>
                    <ModalBody>
                        <SearchForm onClose={onClose} categories={categories}/>
                    </ModalBody>
                </>
                )}
            </ModalContent>
        </Modal>
    );
}

