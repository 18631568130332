import { Config } from '@/src/constant';
import { Button } from '@nextui-org/button';
import Link from 'next/link';
import React from 'react'
import {BsTelegram} from "react-icons/bs";

export default function SocialButtonList() {
    const {phone} = Config;
    const socialList = [
        {
            icon: <BsTelegram/>,
            name: "Telegram",
            url: `https://t.me/${phone}`,
        },
        // {
        //     icon: <BsInstagram/>,
        //     name: "Instagram",
        //     url: "/",
        // },
        // {
        //     icon: <BsTwitter/>,
        //     name: "Twitter",
        //     url: "/",
        // },
        // {
        //     icon: <BsGoogle/>,
        //     name: "Google",
        //     url: "/",
        // },
        // {
        //     icon: <BsYoutube/>,
        //     name: "YouTube",
        //     url: "/",
        // },
    ];
    return (
        <div className="flex gap-2">
            {socialList.map((item,i) => (
                <Button as={Link} href={item.url} aria-label={item.name} size="sm" key={i} radius="full" isIconOnly>{item.icon}</Button>
            ))}
        </div>
    )
}
